import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Adjust the path to your Firebase config
import Navbar from "../component/Navbar";
import Footer from "../component/PageFooter";
import LoadingScreen from "../component/Loading";
import { Helmet } from "react-helmet";

function ArticlePage() {
  const { docId } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticle = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "articles", docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setArticle(docSnap.data());
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching article:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [docId]);

  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }

  if (!article) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <p className="text-center text-red-500">Article not found.</p>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <meta name="description" content={article.title} />
        <title>{article.title}</title>
      </Helmet>

      <Navbar></Navbar>
      <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <h1 className="text-3xl font-bold text-blue-500 mb-6">
          {article.title}
        </h1>
        <p className="text-gray-500 text-sm mb-4">
          <b>Author: {article.alias || "Unknown"}</b> | Created at:{" "}
          {new Date(article.createdAt?.seconds * 1000).toLocaleDateString()} |{" "}
          Last Update:{" "}
          {new Date(article.updatedAt?.seconds * 1000).toLocaleDateString()}
        </p>
        {article.image && (
          <img
            src={article.image}
            alt="Article"
            className="mb-6 rounded-lg shadow-md w-full object-cover"
          />
        )}
        <div
          className="prose prose-lg max-w-none"
          dangerouslySetInnerHTML={{ __html: article.content }}
        ></div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default ArticlePage;
