import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Import Firebase auth
import Logo from "../assets/lokavologo.png";
import { Link } from "react-router-dom";
import { User, LogOut } from "lucide-react";
import { auth, db as firestore } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { logout, login } from "../helper/CheckAuth";

const Navbar = ({ coin }) => {
  const navigate = useNavigate();
  const [nav, setNav] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  const auth = getAuth(); // Initialize Firebase auth
  const [email, setEmail] = useState(null);
  const [token, setToken] = useState(null);

  const handleNav = () => {
    setNav(!nav);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  // Fetch user data from Firestore
  const fetchUserData = async (userId) => {
    const userDocRef = doc(firestore, "users", userId);
    try {
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setEmail(data.email || "Email not available");
        setToken(data.token || "Token not available");
      } else {
        console.error("User document not found.");
        setEmail("Email not available");
        // setToken(0); // Default to 0 if the document doesn't exist
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user); // If a user is logged in, set isLoggedIn to true
      if (user) {
        fetchUserData(user.uid); // Fetch user data if authenticated
      }
    });

    return () => unsubscribe(); // Cleanup the listener on component unmount
  }, [auth]);

  return (
    <div className="flex justify-end md:justify-evenly  mx-auto items-center h-24 max-w-[1240px] px-4 text-white">
      {/* Navigation for larger screens */}
      {/* <ul className="hidden md:flex">
        <button onClick={() => navigate("/Home")} className="p-4">
          <p className="text-[#1E90FF] hover:text-blue-700 transition duration-300 font-montserrat font-bold">
            Home
          </p>
        </button>
        {isLoggedIn && (
          <button onClick={() => navigate("/Dashboard")} className="p-4">
            <p className="text-[#1E90FF] hover:text-blue-700 transition duration-300 font-montserrat font-bold">
              Dashboard
            </p>
          </button>
        )}
        <button onClick={() => navigate("/Contact")} className="p-4">
          <p className="text-[#1E90FF] hover:text-blue-700 transition duration-300 font-montserrat font-bold">
            Contact
          </p>
        </button>
        <button onClick={() => navigate("/About")} className="p-4">
          <p className="text-[#1E90FF] hover:text-blue-700 transition duration-300 font-montserrat font-bold">
            About
          </p>
        </button>
        <button onClick={() => navigate("/FAQ")} className="p-4">
          <p className="text-[#1E90FF] hover:text-blue-700 transition duration-300 font-montserrat font-bold">
            FAQ
          </p>
        </button>
        <button onClick={() => navigate("/Pricing")} className="p-4">
          <p className="text-[#1E90FF] hover:text-blue-700 transition duration-300 font-montserrat font-bold">
            Pricing
          </p>
        </button>
        <button onClick={() => navigate("/Article")} className="p-4">
          <p className="text-[#1E90FF] hover:text-blue-700 transition duration-300 font-montserrat font-bold">
            Blog
          </p>
        </button>
      </ul> */}

      {/* New Version */}

      <nav className="flex items-center  px-6 py-4 bg-black">
        <div className="flex-grow">
          <div className="hidden md:flex  space-x-8">
            <Link
              className="text-[#0066FF] hover:text-blue-400 font-montserrat font-bold"
              to="/"
            >
              Home
            </Link>
            {isLoggedIn && (
              <Link
                className="text-[#0066FF] hover:text-blue-400 font-montserrat font-bold"
                to="/dashboard"
              >
                Dashboard
              </Link>
            )}
            <Link
              className="text-[#0066FF] hover:text-blue-400 font-montserrat font-bold"
              to="/contact"
            >
              Contact
            </Link>
            <Link
              className="text-[#0066FF] hover:text-blue-400 font-montserrat font-bold"
              to="/about"
            >
              About
            </Link>
            <Link
              className="text-[#0066FF] hover:text-blue-400 font-montserrat font-bold"
              to="/faq"
            >
              FAQ
            </Link>
            <Link
              className="text-[#0066FF] hover:text-blue-400 font-montserrat font-bold"
              to="/pricing"
            >
              Pricing
            </Link>
            <Link
              className="text-[#0066FF] hover:text-blue-400 font-montserrat font-bold"
              to="/article"
            >
              Blog
            </Link>
          </div>
        </div>
        {isLoggedIn ? (
          <div className=" absolute right-0 mr-16">
            <button
              onClick={toggleDropdown}
              className="flex items-center text-[#0066FF]  hover:text-blue-400 focus:outline-none"
            >
              <div className="h-8 w-8 flex items-center justify-center rounded-full bg-white">
                <User className="h-5 w-5" />
              </div>
              <span className="sr-only">User menu</span>
            </button>
            {isDropdownOpen && (
              <div
                className="absolute right-0 mt-2 w-72 bg-white shadow-lg rounded-md text-black"
                onMouseLeave={closeDropdown}
              >
                <div className="items-center px-4 py-2 border-b ">
                  {/* <p className="justify-center font-medium pr-4">My Account</p> */}
                  <button
                    onClick={() => navigate("/dashboard")}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 font-medium rounded-lg text-sm px-4 py-2"
                  >
                    My Dashboard
                  </button>
                </div>
                <div className="px-4 py-2">
                  <p className="text-sm font-medium">Email</p>
                  <p className="text-sm text-gray-500">{email}</p>
                </div>
                <div className="px-4 py-2 border-b">
                  <p className="text-sm font-medium">Token</p>
                  <p className="text-sm text-gray-500 truncate">
                    {coin ? coin : token}
                  </p>
                </div>
                <div className="px-4 py-2">
                  <button
                    onClick={logout}
                    className="flex items-center text-red-600 hover:text-red-800 w-full focus:outline-none"
                  >
                    <LogOut className="h-4 w-4 mr-2" />
                    Log out
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="absolute right-0 mr-16">
            <button
              onClick={login}
              className="flex items-center text-red-600 hover:text-red-800 w-full focus:outline-none"
            >
              <LogOut className="h-4 w-4 mr-2" />
              Login
            </button>
          </div>
        )}
      </nav>

      {/* End of New Version */}

      {/* Hamburger menu for smaller screens */}
      <div onClick={handleNav} className="block md:hidden">
        {!nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile menu */}
      <div
        className={
          !nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 z-50"
            : "fixed left-[-100%] z-50"
        }
      >
        <img src={Logo} alt="Logo" className="m-4" />
        <ul className="uppercase p-4 ">
          <li
            onClick={() => navigate("/Home")}
            className="p-4 border-b border-gray-600 hover:text-blue-700 transition duration-300 font-montserrat font-semibold"
          >
            Home
          </li>
          {isLoggedIn && (
            <li
              onClick={() => navigate("/Dashboard")}
              className="p-4 border-b border-gray-600 hover:text-blue-700 transition duration-300 font-montserrat font-semibold"
            >
              Dashboard
            </li>
          )}
          <li
            onClick={() => navigate("/Contact")}
            className="p-4 border-b border-gray-600 hover:text-blue-700 transition duration-300 font-montserrat font-semibold"
          >
            Contact
          </li>
          <li
            onClick={() => navigate("/FAQ")}
            className="p-4 border-b border-gray-600 hover:text-blue-700 transition duration-300 font-montserrat font-semibold"
          >
            FAQ
          </li>
          <li
            onClick={() => navigate("/About")}
            className="p-4 border-b border-gray-600 hover:text-blue-700 transition duration-300 font-montserrat font-semibold"
          >
            About
          </li>
          <li
            onClick={() => navigate("/Pricing")}
            className="p-4 border-b border-gray-600 hover:text-blue-700 transition duration-300 font-montserrat font-semibold"
          >
            Pricing
          </li>
          <li
            onClick={() => navigate("/Article")}
            className="p-4 border-b border-gray-600 hover:text-blue-700 transition duration-300 font-montserrat font-semibold"
          >
            Blog
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
