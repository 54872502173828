// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAsCU1ai2JFV_lUKGfh-BND602g1KUnhas",
  authDomain: "lokavo-a2e1d.firebaseapp.com",
  projectId: "lokavo-a2e1d",
  storageBucket: "lokavo-a2e1d.appspot.com",
  messagingSenderId: "682105224600",
  appId: "1:682105224600:web:09110760b98a2d9db67fed",
  measurementId: "G-0GREN0J6T6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);
const db = getFirestore(app);

export { db, auth, provider, storage };
