import React, { useState, useEffect } from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/PageFooter";
import { useNavigate } from "react-router-dom";

import MapsLocationDisplay from "../component/LocationMap";
import { auth, db as firestore } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

import ProfileSection from "../component/Profile";
import ComingSoon from "../component/ComingSoon";
import { useAuth } from "../helper/CheckAuth";

export default function Dashboard() {
  const navigate = useNavigate();

  const { isAuthenticated, initialLoadComplete } = useAuth();

  // const [isAuthenticated, setIsAuthenticated] = useState(null);
  // // const [isProfileComplete, setIsProfileComplete] = useState(null);
  // const [initialLoadComplete, setInitialLoadComplete] = useState(false); // Track initial load completion

  const [token, setToken] = useState(null);

  // Fetch initial trial chances from Firestore
  const fetchtoken = async (userId) => {
    const userDocRef = doc(firestore, "users", userId);
    try {
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        setToken(docSnap.data().token || 0);
      } else {
        setToken(0); // Default to 0 if no document
      }
    } catch (error) {
      console.error("Error fetching trial chances:", error);
    }
  };

  // useEffect(() => {
  //   // Listen to authentication state changes
  //   const unsubscribe = auth.onAuthStateChanged(async (user) => {
  //     if (user) {
  //       setIsAuthenticated(true);
  //     } else {
  //       setIsAuthenticated(false);
  //       navigate("/Home");
  //       // setIsProfileComplete(false);
  //     }

  //     // Set initial load as complete after auth and profile checks
  //     setInitialLoadComplete(true);
  //   });

  //   return unsubscribe; // Cleanup the listener on component unmount
  // }, []);

  // Listen to authentication state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchtoken(user.uid);
      } else {
        setToken(null); // Reset state when user logs out
      }
    });
    return () => unsubscribe();
  }, []);

  // Display loading spinner or message until initial load is complete
  if (!initialLoadComplete) {
    return (
      <div className="flex items-center justify-center h-screen bg-black text-white">
        <p className="text-xl">Loading...</p>
      </div>
    );
  }
  return (
    <div className="bg-black ">
      <Navbar coin={token}></Navbar>

      <ProfileSection token={token}></ProfileSection>
      <MapsLocationDisplay
        token={token}
        setToken={setToken}
      ></MapsLocationDisplay>
      <ComingSoon></ComingSoon>

      <Footer></Footer>
    </div>
  );
}
